<template>
<div class="buscador" :class="buscador ? 'buscadorWidht' : ''">
    <a class="btnBuscarChat" :class="buscador ? 'buscador-off' : 'buscador-on'" @click="buscador = !buscador">
        <img src="../assets/images/lupa.svg" alt="" />
    </a>
    <input id="busquedaChat" v-model="busqueda" @input="buscar()" class="buscadorInput" :class="buscador ? 'buscador-on' : 'buscador-off'" type="search" placeholder="Buscar por número de teléfono" aria-expanded="false">
    
    <!-- <div class="dropdown m-2 d-flex" id="etiqueta">
        <button v-if="asesor.rol_id<3" @click="monitorear" class="btn  border-gris" data-toggle="modal" title="Traspaso general" data-target="#traspasarChat"><font-awesome-icon icon="eye"/></button>
        <button @click="traspaso" class="btn  border-gris" data-toggle="modal" title="Traspaso general" data-target="#traspasarChat"><font-awesome-icon icon="exchange-alt"/></button>
        <div>
            <button class="btn btn-secondary dropdown-toggle btnEtiqueta" type="button" id="etiqueta" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <font-awesome-icon icon="tags" :class="{'': tags==0, 'tQueja': tags==1, 'tAtendido': tags==2, 'tConsulta': tags==3,'tRH': tags==4 }"></font-awesome-icon>
            </button>
            <div class="dropdown-menu" aria-labelledby="etiqueta">
                <a class="dropdown-item blanco" @click="tags=0, buscarEtiqueta()"><font-awesome-icon icon="tags" /> Todos</a>
                <a class="dropdown-item tQueja" @click="tags=1, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tQueja" /> Queja</a>
                <a class="dropdown-item tAtendido" @click="tags=2, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tAtendido" /> Atendido</a>
                <a class="dropdown-item tConsulta" @click="tags=3, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tConsulta" /> Credito</a>
                <a class="dropdown-item tRH" @click="tags=4, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tRH" /> Ventas </a>
            </div>
        </div>
    </div> -->
</div>

</template>

<script>
export default {
    name: 'Buscar',
    components: {
        
    },
    data() {
        return {
            busqueda: '',
            tags:0,
            asesor: JSON.parse(sessionStorage.getItem('sesion')),
            buscador: false
        }
    },
    methods: {
        buscar(){
            this.$bus.$emit('buscar', this.busqueda); 
        },
        traspaso(){
            this.$bus.$emit('multiple', 1);
        },
        monitorear(){
            this.$bus.$emit('monitorear', 4);
        },
        buscarEtiqueta(){
            this.$bus.$emit('buscarXetiqueta', this.tags); 
            this.$bus.$emit('pedirListaChats', 1); 
        },
    },
    watch: {
        busqueda: function(){
            if(this.busqueda == '') {
                this.buscador = false
            }
        }
    } 

}
</script>
