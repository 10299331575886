<template>
<!-- Modal traspasar chat -->
<div v-if="modalActive" class="modal" id="traspasarChat" tabindex="-1" role="dialog" aria-labelledby="Traspasar cliente a compañero" aria-hidden="true" @click.prevent="openModal(false)" @keydown.esc.prevent="openModal(false)">
    <div class="modal-dialog modal-dialog-centered" role="document" @click.stop>
        <div class="modal-content">
            <div class="modal-header">
                <h5 v-if="this.tipo==1" class="modal-title">Traspaso de chat masivo</h5>
                <h5 v-else-if="this.tipo==0" class="modal-title" >Traspaso de chat</h5>
                <h5 v-else-if="this.tipo==2" class="modal-title" >Asignar conversación</h5>
                <h5 v-else-if="this.tipo==4" class="modal-title" >Monitorear</h5>
                <button id="closeModal" type="button" class="close close-modal" @click="openModal(false)" data-dismiss="modal" aria-label="Close">
                    <img src="../assets/images/close.svg" alt="" />
                </button>
            </div>
            <div class="modal-body">
                <h5 v-if="this.tipo==1" class="mb-3">Seleccione el asesor al que se le hará la transferencia y la etiqueta deseada.</h5>
                <h5 v-else-if="this.tipo==0" class="mb-3">Seleccione el asesor al que se le va a transferir el cliente.</h5>
                <h5 v-else-if="this.tipo==2" class="mb-3" >Seleccione COLA o al asesor para asignar cliente.</h5>
                <div v-if="this.alert1" class="alert alert-danger alert-dismissible fade show m-2 mt-3" role="alert">
                    Seleccionar asesor
                    <button @click="cerrar" type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="tipo==4" style="width:400px" class="d-flex flex-column">
                    <p>Selecciona el asesor al que deseas monitorear</p>
                    <div class="d-flex justify-center aling-center">
                        <v-select style="flex:1 0 auto" placeholder="Buscar usuario" :options="asesores" label="nombre"  v-model="destino_usuario" @input="monitorearUsuario()">
                            <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                No se encontró un usuario llamado: <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                            </template>
                        </v-select>
                        <button class="btn-sm btn-danger pt-0 ml-2" @click="noMonitorearUsuario()" style="height:28px">Restablecer</button>
                    </div>
                    <p class="mt-3">Introduce el numero del Cliente a buscar:</p>
                    <div class="input-group input-group-sm">
                        <input v-model="buscarNumero" @keypress.enter="verHistorial" type="text" class="form-control" placeholder="Numero de telefono" max="13" maxlength="13" aria-label="" aria-describedby="basic-addon1">
                        <div class="input-group-append">
                            <button @click="verHistorial" class="btn btn-info" type="button"><font-awesome-icon icon="search" /></button>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <!-- <select v-if="this.tipo!=4" ref="asesor" class="custom-select col-10" :class="{'col-12': this.tipo!=1}">
                            <option v-if="this.tipo!=2" disabled selected :value="0">Asesor disponible</option>
                            <option v-if="this.tipo==2" selected :value="0">Enviar a cola</option>
                            <option v-if="this.tipo==2" :value="sesion.id">{{sesion.nombre}}</option>
                        </select> -->
                        <v-select v-if="this.tipo!=4" style="flex:1 0 auto" placeholder="Asesor disponible" :options="asesores" label="nombre"  v-model="asesor">
                            <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                No se encontró un usuario llamado: <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                            </template>
                        </v-select>
                        <div class="btn-group dropleft m-auto departamentoContenedor" :class="{'d-none': this.tipo!=1}">
                            <div class="border-red" id="etiqueta" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <a>
                                    <img src="../assets/images/departamento.svg" alt="" />
                                </a>
                                <span>Departamento</span>
                            </div>    
                            <div class="dropdownEtiquetas" aria-labelledby="etiqueta">
                                <a class="blanco" @click="tags=0"><img src="../assets/images/Etiquetas/etiqueta-todos.svg" alt="" /> Todos</a>
                                <a class="tQueja" @click="tags=1"><img src="../assets/images/Etiquetas/naranja.svg" alt="" /> Queja</a>
                                <a class="tAtendido" @click="tags=2"><img src="../assets/images/Etiquetas/verde.svg" alt="" /> Atendido</a>
                                <a class="tConsulta" @click="tags=3"><img src="../assets/images/Etiquetas/morado.svg" alt="" /> Crédito</a>
                                <a class="tRH" @click="tags=4"><img src="../assets/images/Etiquetas/azul.svg" alt="" />Ventas </a>
                            </div>
                            <!-- <button class="btn btn-secondary dropdown-toggle btnEtiqueta" type="button" id="etiqueta" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <font-awesome-icon icon="tags" :class="{'': tags==0, 'tQueja': tags==1, 'tAtendido': tags==2, 'tConsulta': tags==3,'tRH': tags==4 }"></font-awesome-icon>
                            </button>
                            <div class="dropdown-menu col-1 dropLeftTraspaso " aria-labelledby="etiqueta">
                                <a class="dropdown-item" @click="tags=0"><font-awesome-icon icon="tags" /> Todos</a>
                                <a class="dropdown-item tQueja" @click="tags=1"><font-awesome-icon icon="tag" class="tQueja" /> Quejas</a>
                                <a class="dropdown-item tAtendido" @click="tags=2"><font-awesome-icon icon="tag" class="tAtendido" /> Atendido</a>
                                <a class="dropdown-item tConsulta" @click="tags=3"><font-awesome-icon icon="tag" class="tConsulta" /> Credito</a>
                                <a class="dropdown-item tRH" @click="tags=4"><font-awesome-icon icon="tag" class="tRH" /> Ventas</a>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div v-if="this.alert2" class="alert alert-danger alert-dismissible fade show m-2 mt-3" role="alert">
                    Seleccionar motivo
                    <button @click="cerrar2" type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <select v-if="this.tipo!=4" ref="seleccionado"  class="custom-select mt-3" :class="{'d-none': this.tipo==2}">
                    <option disabled selected value="0">Motivo</option>
                    <option v-for="(motivo) in motivos" :key="motivo.id"  :value="motivo.id" >{{motivo.descripcion}}</option> 
                </select>
            </div>
            <div class="modal-footer" v-if="tipo!=4">
                <button   type="button" class="btn btn-secondary" @click="openModal(false)" data-dismiss="modal" ref="myBtn">Cancelar</button>
                <button type="button" class="btn btn-primary" @click="traspasarPaciente">Traspasar</button>
            </div>
        </div>
    </div>
    <div class="modalFondo"></div>
</div>
</template>

<script>
import asesorService from '../services/asesor'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTag, faTags } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faTag, faTags)
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
    name: 'TrasferirChat',
    data() {
        return {
            asesores: [],
            selected: '',
            seleccionado:'',
            motivos:'',
            tipo: 0,
            alert1: false,
            alert2: false,
            tags:0,
            destino_usuario:null,
            sesion: JSON.parse(sessionStorage.getItem('sesion')),
            buscarNumero: '521',
            asesor:null,
            modalActive: false
        }
    },
    components:{
        FontAwesomeIcon,
        vSelect
    },
    created() {
        this.$bus.$off('sencillo')
        this.$bus.$on('sencillo', () => {
            this.tipo=0
            this.openModal(true)
        })
        this.$bus.$off('multiple')
        this.$bus.$on('multiple', () => {
            this.tipo=1
            this.openModal(true)
        } )
        this.$bus.$off('abrirInbox')
        this.$bus.$on('abrirInbox', () => {
            this.tipo=2
            this.openModal(true)
        } )
        this.$bus.$off('desdeParametros')
        this.$bus.$on('desdeParametros', () => {
            this.tipo=3
            this.openModal(true)
        } )
        this.$bus.$off('monitorear')
        this.$bus.$on('monitorear', () => {
            this.tipo=4
            this.openModal(true)
        } )
        this.$bus.$off('modalTraspasos')
        this.$bus.$on('modalTraspasos', () => {
            this.openModal(true)
        } )
    },
    methods: {
        listaAsesores() {
            asesorService.listaDeAsesores().then(respa => {
                
                if(this.sesion.rol_id<=3||this.sesion.id==46){
                  let yo ={
                      id: this.sesion.id,
                      nombre: this.sesion.nombre
                  }
                  let cola = {
                      id: 0,
                      nombre: 'COLA'
                  }
                  this.asesores = respa
                  this.asesores.push(yo)
                  this.asesores.push(cola)
                }else{
                    
                     this.asesores = respa.filter(obj=>{
                      return obj.id==10 || obj.id==21 || obj.id==25 || obj.id==82 || obj.id==47 || obj.id==46 || obj.id==58 || obj.id==86|| obj.id==97|| obj.id==85|| obj.id==20|| obj.id==99
                      //return obj.id!=32 && obj.id!=16 && obj.id!=31 && obj.id!=41 && obj.id!=1 && obj.id!=4 && obj.id!=5
                      // 32: Julio, 16: Monitor, 31: Carlos Morales, 41: Alejandro Urias, 1: Axel, 4: Pedrito, 5: Hever
                })
                }
            })
        },
        cerrar(){
            this.alert1 = false
        },
        cerrar2(){
            this.alert2 = false
        },
        traspasarPaciente() {
            let sender = sessionStorage.getItem('sender')
            this.seleccionado = this.$refs.seleccionado.value

            // this.$gtag.event('traspasarPaciente',{
            //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
            //     'event_label': 'Total Traspasos',
            //     'value': 1
            // })
            if (this.asesor.id <= 0 && (this.tipo<=1) ) {
                this.alert1 = true
                return
            }

            if ((this.seleccionado <=0)&&(this.tipo<=1) ) {
                this.alert2 = true
                return
            }  

            if (this.tipo==3){
                asesorService.traspasarPacientesParametros(this.asesor.id,this.seleccionado).then(()=>{  
                    this.$bus.$emit('actualizar-pacientes_transf')
                    const elem = this.$refs.myBtn
                    elem.click() 
                })
            } else if (this.tipo==1){
               if (this.tags!=0){
                   let etiqueta= this.tags==1? "'queja':true": this.tags==2?"'atendido':true": this.tags==3?"'credito':true": "'ventas':true"
                   asesorService.traspasarPacientesConEtiqueta(this.asesor.id,this.seleccionado, etiqueta).then(()=>{  
                        this.$bus.$emit('actualizar-pacientes_transf')
                        const elem = this.$refs.myBtn
                        elem.click() 
                        sessionStorage.removeItem('senderid')
                        sessionStorage.removeItem('sender_actual')
                    })
               } else{
                   asesorService.traspasarPacientesMasivo(this.asesor.id,this.seleccionado).then(()=>{  
                        this.$bus.$emit('actualizar-pacientes_transf')
                        const elem = this.$refs.myBtn
                        elem.click() 
                        sessionStorage.removeItem('senderid')
                        sessionStorage.removeItem('sender_actual')
                    })
               }
           } else if (this.tipo==0) {
               asesorService.traspasarPaciente(sender,this.asesor.id,this.seleccionado).then(()=>{  
                    this.$bus.$emit('actualizar-pacientes_transf')
                    const elem = this.$refs.myBtn
                    elem.click()
                    sessionStorage.removeItem('sender_actual')
                })
           } else {

               if(this.asesor.id>0){
                   asesorService.traspasarPaciente(sender,this.asesor.id,5).then(()=>{    
                        const elem = this.$refs.myBtn
                        elem.click() 
                        
                    })
                    return true
               }else{
                    asesorService.traspasarPacientePlataforma(sender).then(()=>{    
                        const elem = this.$refs.myBtn
                        elem.click() 
                        sessionStorage.setItem('sender','')  
                        return true 
                    })
               } 
                this.alert1 = false
                this.alert2 = false
                this.modalActive = false
                document.body.classList.remove('modal-open')
                document.getElementsByClassName('modal-backdrop').remove()
           }
        },
        listaMotivos() {
            asesorService.listaMotivos().then(mot=>{ 
                this.motivos = JSON.parse(JSON.stringify(mot))  
            })
        },
        monitorearUsuario(){
            sessionStorage.setItem('asesorId', this.destino_usuario.id)
            this.$bus.$emit('pedirListaChats', 1)
        },
        noMonitorearUsuario(){
            this.destino_usuario=null
            let asesor = JSON.parse(sessionStorage.getItem('sesion'))
            sessionStorage.setItem('asesorId', asesor.id)
            this.$bus.$emit('pedirListaChats', 1)
        },
        verHistorial(){
            sessionStorage.setItem('sender_actual', this.buscarNumero)
        },
        openModal(opc){
            console.log('modal')
            console.log(document.body.classList)
            console.log(document.getElementsByClassName('modal-open').classList)
            this.modalActive = opc
        }
    },
    mounted() {
        this.listaAsesores()
        this.listaMotivos()

    },
    
}
</script>
