<template>
<header>
  <nav class="navbar navbar-expand-lg navbar-light container-fluid" :class="ruta=='/chat' ? '' : 'widhtNav'">
    <div class="contenedorBuscador">
        <div v-show="ruta!='/chat'" class="logoLeft">
            <a @click="dashboard" class="pointer" v-if="sessionStorageUser.rol_id<=2" title="Ir a Dashboard">
                <img id="img-logoNav" src="../assets/images/logo-vb.png" height="50px" alt="" />
            </a>
            <a v-else title="Logo">
                <img id="img-logoNav" src="../assets/images/logo-vb.png" height="50px" alt="" />
            </a>
        </div>
        <buscar v-show="ruta=='/chat'" />
        <div v-show="ruta=='/chat'" class="switch-network row">
          <div class="btn-network rounded-circle" :class="{'bg-whatsapp': network==0}" @click="seleccionarWA">
            <i class="icon-whatsapp opt-wa"><font-awesome-icon :icon="['fab', 'whatsapp']" size="1x"/></i>
          </div>            
            <div class="btn-network rounded-circle" :class="{'bg-messenger': network==1}" @click="seleccionarFBMX">
                <i class="icon-messenger-new opt-fb"><font-awesome-icon :icon="['fab', 'facebook-messenger']" size="1x"/></i>
            </div>
        </div>
    </div>
    <div class="contenedorEtiquetas">
        <div class="logo-nav">
            <a @click="dashboard" class="pointer" v-if="sessionStorageUser.rol_id<=2" title="Ir a Dashboard">
                <img id="img-logoNav" src="../assets/images/logo-vb.png" alt="" />
            </a>
            <a v-else title="Logo">
                <img id="img-logoNav" src="../assets/images/logo-vb.png" alt="" />
            </a>
        </div>
        <div class="nombre-nav">
            <img src="../assets/images/imagen-usuario.svg" alt="" />
            <div class="name">
                <span class="title">{{sessionStorageUser.nombre}}</span>
                <span class="subtitulo" v-if="sessionStorageUser.rol_id == 1">Administrador</span><span class="subtitulo" v-else-if="sessionStorageUser.rol_id == 2">Moderador</span><span class="subtitulo" v-else-if="sessionStorageUser.rol_id == 4">Colabolador de apoyo</span><span class="subtitulo" v-else>Asesor</span>
            </div>
        </div>
        <div class="ico-menu-nav pointer" @click="menu = !menu">
            <div v-if="!menu" class="icono-menu"></div>
            <div v-if="!menu" class="icono-menu"></div>
            <div v-if="!menu" class="icono-menu"></div>
            <img id="img-cerrarNav" v-if="menu" src="../assets/images/icon-x-claro.svg" alt="" />
        </div>
    </div>
    <div v-if="menu" class="menu-nav">
        <div class="etiquetas-menu">
            <img src="../assets/images/imagen-usuario.svg" alt="" />
            <div class="name">
                <span class="title">{{sessionStorageUser.nombre}}</span>
                <span class="subtitulo" v-if="sessionStorageUser.rol_id == 1">Administrador</span><span class="subtitulo" v-else-if="sessionStorageUser.rol_id == 2">Moderador</span><span class="subtitulo" v-else-if="sessionStorageUser.rol_id == 4">Colabolador de apoyo</span><span class="subtitulo" v-else>Asesor</span>
            </div>
        </div>
        <div class="opcionesMenu">
            <div class="sliceMenu" @click="darkMode">
                <img id="img-darkOnNav" src="../assets/images/off-modosc-claro.svg" alt="" />
                <transition name="slide">
                    <span>Modo oscuro</span>
                </transition>
            </div> 
            <div class="sliceMenu second" @click="cerrarSesion">
                <img id="img-sesionNav" src="../assets/images/cerrses-icon-claro.svg" alt="" />
                <transition name="slide">
                    <span>Cerrar sesión</span>
                </transition>
            </div>      
        </div>
    </div>
    <!-- <div class="w-50">
      <ul class="navbar-nav ">
        <li class="aviso"></li>
        <li class="nav-item dropdown controldeAccion mr-3 d-flex">
            <div id="accordion" role="tablist" v-click-afuera="fueraPerfil">
                <div id="contenedorPerfil" class="borderC">
                  <div @click="dropdown" class="card-header transparente p-0" role="tab" id="headingOne">
                    <h5 class="mb-0" id="menuPerfil">
                      <a  class="nav-link d-flex align-items-center justify-content-end letra16 dropbtn" aria-expanded="true" aria-controls="miPerfil">
                        <strong class="d-none d-sm-none d-md-none d-lg-block ml-1">{{sessionStorageUser.nombre}}</strong>
                        <i class="icon-angle-down-1" id="toggleFlecha"></i>
                      </a>
                    </h5>
                  </div>

                  <div id="miPerfil" class="collapse dropdown-content" role="tabpanel" aria-labelledby="miPerfil">
                    <div class="card-body bg-perfil p-0">
                      <div v-show="ruta=='/chat'" class="switch custom-switch">
                        <input v-model="thema" type="checkbox" class="custom-control-input darkMode" id="darkMode" @click="darkMode" />
                        <label class="custom-control-label h7" for="darkMode">Modo oscuro</label>
                      </div>
                      <a v-show="ruta=='/chat'" v-if="sessionStorageUser.rol_id<=2" class="dropdown-item border-top-0" @click="dashboard"><font-awesome-icon icon="home" /> Dashboard</a>
                      <a class="dropdown-item border-top-0" @click="miniPerfil" id="miniPerfil" data-toggle="collapse" aria-expanded="true" aria-controls="perfil">
                        <font-awesome-icon :icon="['fas','address-card']" />&nbsp; Mi perfil
                      </a>
                      <div id="perfil" class="collapse" role="tabpanel" aria-labelledby="perfil">
                        <p>
                          <strong>Nombre:</strong> {{sessionStorageUser.nombre}}
                        </p>             
                        <p>
                          <strong>Tipo de usuario:</strong> <span v-if="sessionStorageUser.rol_id == 1">Administrador</span><span v-else-if="sessionStorageUser.rol_id == 2">Moderador</span><span v-else-if="sessionStorageUser.rol_id == 4">Colabolador de apoyo</span><span v-else>Asesor</span>
                        </p>

                      </div>
                      <div v-if="(sessionStorageUser.id ==(13)||sessionStorageUser.id ==(3))" v-show="ruta=='/chat'" class="switch custom-switch">
                        <input type="checkbox" class="custom-control-input darkMode" id="SwitchASESOR" :checked="!checked" @click="cambiarSwitch"/>
                        <label class="custom-control-label h7" for="SwitchASESOR">Activar Asesor</label>
                      </div>
                      <div>
                        <a @click="cerrarSesion" class="dropdown-item bUltimo click"><font-awesome-icon icon="sign-out-alt" /> &nbsp;Cerrar sesión</a>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </li>
      </ul>
    </div> -->
  </nav>
</header>
</template>

<style>
.click {
    cursor: pointer;
}
</style>

<script>
// import Avisos from './Avisos.vue'
import asesorService from '../services/asesor'
import controlesServices from '../services/controles'
import Buscar from './Buscar.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookMessenger, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faUserCircle, faAddressCard, faSignOutAlt, faHome, faBell, faPaperclip, faMicroscope } from '@fortawesome/free-solid-svg-icons'
import { faBell as Campana, faHandPaper, faChartBar, faHospital, faGrinStars } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserCircle, faAddressCard, faSignOutAlt, faHome, faBell, Campana,faHandPaper, faChartBar, faHospital, faGrinStars, faPaperclip, faMicroscope,faWhatsapp,faFacebookMessenger)
export default {
    name: "Navbar",
    data() {
        return {
            cambiarFlecha: false,
            cambiarMenu: false,
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            ruta: window.location.pathname,
            status: false,
            checked:false,
            switch:false,
            tema: JSON.parse(localStorage.getItem('tema')),
            thema:true,
            atendidos: 0,
            network: 0,
            showCampana:false,
            notifications:null,
            automatizacion:0,
            menu: false

        };
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Buscar
    },
    created(){
        if(this.ruta=='/chat'){
            if(this.tema){
            document.documentElement.classList.toggle('dark-mode')
            this.thema=true
            }else{
                this.thema=false
            }
        }
        if(localStorage.getItem('Network')=='WA'){
            this.network=0
            this.seleccionarWA()
        } else if(localStorage.getItem('Network')=='FB'){
            this.network=1
            this.seleccionarFBMX()
        }
        else{
            this.network=0
            this.seleccionarWA()
        }
        
        
    },
    mounted(){
        console.log(this.sessionStorageUser)
        if((this.sessionStorageUser.id ==(9)||this.sessionStorageUser.id ==(13)||this.sessionStorageUser.id ==(11)||this.sessionStorageUser.id ==(10)||this.sessionStorageUser.id ==(3))){this.get()}
    },
    directives: {
        "click-afuera": {
        bind: function(el, binding) {
            // Define ourClickEventHandler
            const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                // as we are attaching an click event listern to the document (below)
                // ensure the events target is outside the element or a child of it
                binding.value(event); // before binding it
            }
            };
            // attached the handler to the element so we can remove it later easily
            el._vueClickEventHandler_ = ourClickEventHandler;

            // attaching ourClickEventHandler to a listener on the document here
            document.addEventListener("click", ourClickEventHandler);
        },
        unbind: function(el) {
            // Remove Event Listeners
            document.removeEventListener("click", el._vueClickEventHandler_);
        }
        }
    },
    methods: {
        darkMode(){
            document.documentElement.classList.toggle('dark-mode')
            localStorage.setItem('tema', JSON.parse(!JSON.parse(localStorage.getItem('tema'))))
        },
        fueraPerfil(){
            var div = document.getElementById("miPerfil")
            var perfil = document.getElementById("perfil")
            var menuPerfil = document.getElementById("menuPerfil")
            var flecha = document.getElementById("toggleFlecha")
            
            
            
            if (perfil.classList.contains('show')) {
                perfil.classList.remove('show')
                menuPerfil.classList.toggle("menuPerfil")
            }
            
                div.classList.remove('show')
                flecha.classList.remove("icon-angle-up-1")
                flecha.classList.add("icon-angle-down-1")
            
        },
        toggled() {
            var flecha = document.getElementById("toggleFlecha");
            flecha.classList.toggle("icon-angle-up-1");
            flecha.classList.toggle("icon-angle-down-1");
            var menuPerfil = document.getElementById("menuPerfil");
            var perfil = document.getElementById("perfil");
            if (perfil.classList.contains("show")) {
                menuPerfil.classList.toggle("menuPerfil");
                perfil.classList.remove("show");
            }
        },
        miniPerfil() {
            var menuPerfil = document.getElementById("menuPerfil");
            var perfil = document.getElementById("perfil");
            perfil.classList.toggle("show");
            menuPerfil.classList.toggle("menuPerfil");
            // this.$gtag.event('verMeta',{
            //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
            //     'event_label': 'Veces que se quizo ver la meta',
            //     'value': 1
            // })
            // this.getAtendidos()
        },
        dropdown() {
            document.getElementById("miPerfil").classList.toggle("show");
            this.toggled();
        },
        cerrarSesion() {
            this.status=true
            sessionStorage.clear()
            window.location.href = '/'
            this.status=false
        },
        dashboard() {
            window.location.href = '/dashboard'
        },
        resultados() {
            window.location.href = '/resultados'
        },
        get(){
            controlesServices.Control().then(resp => {
                resp.map(x => {
                    if(x.id==1){
                        this.switch=x
                    } else{
                        return
                    }
                    return x
                })
                
                if(this.switch.status==0){
                    this.checked=false
                }else{
                    this.checked=true
                }
            })
        },
        cambiarSwitch(){
            let variable
            variable = this.checked ? 0:1
            controlesServices.CambiarControl(1, variable).then(() => {
                this.get()
            })
        },
        getAtendidos(){
            asesorService.Atendidos().then(resp => {
                this.atendidos = resp[0].atendidos
                this.automatizacion = resp[0].automatizacion
            })
        },
        cambiarNetwork(){
            if(this.network){
                localStorage.setItem('Network', 'WA')
            } else {
                localStorage.setItem('Network', 'FB')
            }
        },
        seleccionarWA(){
            localStorage.setItem('Network', 'WA')
            this.network=0
            // console.log('WHATSAPP selected')
            asesorService.actualizarNetwork('WA')
            this.seleccionado()
        },
        seleccionarWAni(){
            localStorage.setItem('Network', 'WAni')
            this.network=3
            // console.log('WHATSAPP selected')
            asesorService.actualizarNetwork('WAni')
            this.seleccionado()
        },
        seleccionarFBMX(){
            localStorage.setItem('Network', 'FB')
            this.network=1
            // console.log('Facebook selected')
            asesorService.actualizarNetwork('FB')
            this.seleccionado()
        },
        seleccionarFBNI(){
            localStorage.setItem('Network', 'FBni')
            this.network=2
            // console.log('Nicaragua')
            asesorService.actualizarNetwork('FBni')
            this.seleccionado()
        },
        seleccionado(){
            this.$bus.$emit('pedirListaChats')
            sessionStorage.removeItem('sender')
            sessionStorage.removeItem('sender_actual')
            this.$bus.$emit('cambioNetwork')
        }

    }
};
</script>